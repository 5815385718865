<template>
    <div class="container">
        <h1>Shaun's Super Test Page</h1>
    </div>
</template>

<script>
    console.log(5);
    document.addEventListener('keypress', (event) => {

        if (event.key.length === 1) {
            console.log(event);
        }

        // if (event.code === "Enter") {
        //     alert("Enter");
        // }
    });
</script>

<style scoped>

</style>